import {DatePicker, Stack, Text} from '@fluentui/react';
import {ICustomDatePickerProps} from './DatePicker.types';
import {CustomDatePickerStyles} from './DatePicker.styles';

export const CustomDatePicker = (props: ICustomDatePickerProps) => {
  return (
    <Stack style={CustomDatePickerStyles}>
      <Text variant="medium">{props.label}</Text>
      <DatePicker
        ariaLabel={props.ariaLabel}
        className={props.className}
        isRequired={props.isRequired}
        placeholder={props.placeholder}
        value={props.value}
        maxDate={props.maxDate}
        minDate={props.minDate}
        onSelectDate={props.onSelectDate}
        openOnClick={props.openOnClick}
      />
    </Stack>
  );
};
